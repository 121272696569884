
export const STATIC_PARTNERS = [
  { type: 'MAKLERSPRECHSTUNDE_PARTNER' },
  { type: 'FPRE' },
  { type: 'IMA_MARKETREPORT' },
  { type: 'IMMO_BILLIE_PARTNER' },
  { type: 'REAL_X_LAB_PARTNER' },
  { type: 'LOCAL_ON_TOP_PARTNER' },
  // { type: 'CLICKLIFT' },
  // { type: 'ROCKET_SUITS' },
  // { type: 'STORYBOX' }, // temporaryily hidden due to partner request; should be enabled again in ca. 6 months
  { type: 'IMMOVIEWER' },
  { type: 'REGIONALIMMOBILIEN' },
  { type: 'IMOGENT' },
  // { type: 'SINCHEN_MEDIA' }, // temporaryily hidden due to partner request; should be enabled again in ca. 6 months
  { type: 'MAXX_CAPITAL' },
  { type: 'MAXX_FINANCE' },
  { type: 'PIXELWERKSTATT' },
  { type: 'JACASA' }
]

export const PARTNER_ORDER = [
  'PIXELWERKSATT',
  'VON_FRED',
  'REDCUP',
  'LOCAL_ON_TOP_PARTNER',
  'MAXX_CAPITAL',
  'MAXX_FINANCE',
  'MECOA',
  // 'SINCHEN_MEDIA', // temporaryily hidden due to partner request; should be enabled again in ca. 6 months
  // 'MULTIPHONE', // temporaryily hidden; check registration link before enabling again
  'CLICKLIFT',
  'FIFY',
  'ROCKET_SUITS',
  'BEWERTUNGSLOESCHER',
  'IMA_MARKETREPORT',
  'FPRE',
  'SOLOWERK',
  // 'STORYBOX', // temporaryily hidden due to partner request; should be enabled again in ca. 6 months
  'PIXELMOTION',
  'IMMOVIEWER',
  'REGIONALIMMOBILIEN',
  'IMOGENT',
  'IMMO_BILLIE_PARTNER',
  'JACASA',
  'REAL_X_LAB_PARTNER',
  'MAKLERSPRECHSTUNDE',
  'MAKLERSPRECHSTUNDE_PARTNER'
]

export const PARTNER_TAGS = Object.freeze({
  ALL: 'ALL',
  SOCIAL_MEDIA: 'SOCIAL_MEDIA',
  SEO: 'SEO',
  CONTENT: 'CONTENT',
  LEARNING: 'LEARNING',
  LEAD_QUALITY: 'LEAD_QUALITY',
  FEEDBACK_MANAGEMENT: 'FEEDBACK_MANAGEMENT',
  DATA: 'DATA',
  WEBSITE_DEVELOPMENT: 'WEBSITE_DEVELOPMENT',
  OTHER: 'OTHER'
})

export const ReadablePartnerTags = Object.freeze({
  ALL: 'partner.tags.all',
  SOCIAL_MEDIA: 'partner.tags.social-media',
  SEO: 'partner.tags.seo',
  CONTENT: 'partner.tags.content',
  LEARNING: 'partner.tags.learning',
  LEAD_QUALITY: 'partner.tags.lead-quality',
  FEEDBACK_MANAGEMENT: 'partner.tags.feedback-management',
  DATA: 'partner.tags.data',
  WEBSITE_DEVELOPMENT: 'partner.tags.website-development',
  OTHER: 'partner.tags.other'
})

export const PARTNER_TYPES = {
  bottimmo: {
    MAKLERSPRECHSTUNDE: {
      de: {
        subTitle: 'Die Immobilien-Makler-Akademie (IMA) ist das neue Portal für die Online Weiterbildung von Immobilienmaklern.',
        text: ['Hier finden Sie zahlreiche Erklär-Videos von bekannten Trainern und echten Branchen-Experten zu einer riesigen Bandbreite von Themen aus der Immobilienwirtschaft - alles, was Sie interessiert, wichtig für Ihren Erfolg ist und Sie für Ihre Weiterbildungspflicht benötigen!',
          'Als BOTTIMMO-Kunde erhalten Sie mit diesem Gutschein-Code einen kostenfreien Zugang für 12 Monate.',
          'Um auf die Angebote zuzugreifen, registrieren Sie sich bitte auf der Webseite der IMA. Am Ende des Registrierungsprozesses können Sie dann den Gutschein-Code eingegeben, damit der entsprechende Rabatt eingebucht wird.'],
        supportMail: 'office@immobilien-makler-akademie.com',
        registrationLink: 'https://immobilien-makler-akademie.com/de/registrieren',
        logo: 'img/immobilien-makler-akademie.png',
        tags: [PARTNER_TAGS.LEARNING]
      },
      en: {
        subTitle: 'Die Immobilien-Makler-Akademie (IMA) ist das neue Portal für die Online Weiterbildung von Immobilienmaklern.',
        text: ['Hier finden Sie zahlreiche Erklär-Videos von bekannten Trainern und echten Branchen-Experten zu einer riesigen Bandbreite von Themen aus der Immobilienwirtschaft - alles, was Sie interessiert, wichtig für Ihren Erfolg ist und Sie für Ihre Weiterbildungspflicht benötigen!',
          'Als BOTTIMMO-Kunde erhalten Sie mit diesem Gutschein-Code einen kostenfreien Zugang für 12 Monate.',
          'Um auf die Angebote zuzugreifen, registrieren Sie sich bitte auf der Webseite der IMA. Am Ende des Registrierungsprozesses können Sie dann den Gutschein-Code eingegeben, damit der entsprechende Rabatt eingebucht wird.'],
        supportMail: 'office@immobilien-makler-akademie.com',
        registrationLink: 'https://immobilien-makler-akademie.com/de/registrieren',
        logo: 'img/immobilien-makler-akademie.png',
        tags: [PARTNER_TAGS.LEARNING]
      }
    },
    PIXELMOTION: {
      de: {
        subTitle: 'Mit unseren personalisierten Video-Content-Paketen zum professionellen Social Media Auftritt!',
        text: ['Ein häufiges Problem: fehlende Zeit und fehlendes Know-How. Unsere Content-Pakete enthalten sofort einsatzbereite Inhalte für Ihre Social Media Kanäle und Ihre Website - personalisiert auf Ihr Unternehmen für größten Wiedererkennungswert. Für Sie bedeutet das einfaches Copy-Paste bis zum fertigen Posting.',
          'Bestellen Sie jetzt mit -20% Rabatt auf unserer Website! Geben Sie dazu einfach Ihren Rabattcode an. Innerhalb weniger Tage erhalten Sie dann Ihre Pakete. Sie zahlen nur einmalig - kein Abo und keine Extrakosten.'],
        registrationLink: 'https://pixelmotion-marketing.de/content-fuer-makler/',
        registrationLinkText: 'Mehr dazu',
        logo: 'img/pixelmotion.png',
        tags: [PARTNER_TAGS.CONTENT]
      },
      en: {
        subTitle: 'Mit unseren personalisierten Video-Content-Paketen zum professionellen Social Media Auftritt!',
        text: ['Ein häufiges Problem: fehlende Zeit und fehlendes Know-How. Unsere Content-Pakete enthalten sofort einsatzbereite Inhalte für Ihre Social Media Kanäle und Ihre Website - personalisiert auf Ihr Unternehmen für größten Wiedererkennungswert. Für Sie bedeutet das einfaches Copy-Paste bis zum fertigen Posting.',
          'Bestellen Sie jetzt mit -20% Rabatt auf unserer Website! Geben Sie dazu einfach Ihren Rabattcode an. Innerhalb weniger Tage erhalten Sie dann Ihre Pakete. Sie zahlen nur einmalig - kein Abo und keine Extrakosten.'],
        registrationLink: 'https://pixelmotion-marketing.de/content-fuer-makler/',
        registrationLinkText: 'Learn more',
        logo: 'img/pixelmotion.png',
        tags: [PARTNER_TAGS.CONTENT]
      }
    },
    MAKLERSPRECHSTUNDE_PARTNER: {
      de: {
        title: 'Maklersprechstunde',
        subTitle: 'Die Maklersprechstunde und die angeschlossene Facebook Gruppe sind das Informations- und Austauschportal der deutschsprachigen Immobilienmakler.',
        text: ['Hier werden die Themen besprochen, die die Branche bewegen. Immer dienstags um 11:00 bespricht Georg Ortner mit ausgewählten Gästen aus Deutschland, Österreich und der Schweiz ein aktuelles Thema. Hier kommen Maklerinnen und Makler mit Experten ins Gespräch, wovon alle profitieren können.'
        ],
        textWithLink: [{
          text: 'Bei Fragen zum Registrierungsprozess wenden Sie sich bitte direkt an den Partner:',
          link: 'https://maklersprechstunde.com/support'
        }, {
          text: 'Facebook Gruppe beitreten:',
          link: 'https://facebook.com/groups/maklersprechstunde'
        }
        ],
        registrationLink: 'https://maklersprechstunde.com/registrieren',
        logo: 'img/maklersprechstunde.png',
        tags: [PARTNER_TAGS.OTHER]
      },
      en: {
        title: 'Maklersprechstunde',
        subTitle: 'Die Maklersprechstunde und die angeschlossene Facebook Gruppe sind das Informations- und Austauschportal der deutschsprachigen Immobilienmakler.',
        text: ['Hier werden die Themen besprochen, die die Branche bewegen. Immer dienstags um 11:00 bespricht Georg Ortner mit ausgewählten Gästen aus Deutschland, Österreich und der Schweiz ein aktuelles Thema. Hier kommen Maklerinnen und Makler mit Experten ins Gespräch, wovon alle profitieren können.'
        ],
        textWithLink: [{
          text: 'Bei Fragen zum Registrierungsprozess wenden Sie sich bitte direkt an den Partner:',
          link: 'https://maklersprechstunde.com/support'
        }, {
          text: 'Facebook Gruppe beitreten:',
          link: 'https://facebook.com/groups/maklersprechstunde'
        }
        ],
        registrationLink: 'https://maklersprechstunde.com/registrieren',
        logo: 'img/maklersprechstunde.png',
        tags: [PARTNER_TAGS.OTHER]
      }
    },
    REAL_X_LAB_PARTNER: {
      de: {
        subTitle: 'RealXLab bietet Coaching, Betreuung und Beratung für Immobilienmakler.',
        text: ['Sie benötigen es individueller oder einen Sparringspartner? Ein Feedback oder Anpassung für die optimale Immobilienmakler-Website? Einen Check/ Betreuung Ihrer (Google/Facebook) Accounts? Die Auswahl der richtigen Software? Hilfe bei der Umsetzung der BOTTIMMO-Inhalte?'],
        registrationLink: 'https://realxlab.com/bottimmo-specials',
        logo: 'img/realxlab.png',
        tags: [PARTNER_TAGS.OTHER],
        code: 'bottimmo',
        textWithLink: [{
          text: 'Profitieren Sie von über 20 Jahre Erfahrung mit Immobilienmaklern von Robert Kampczyk (RealXLab).'
        },
        {
          text: 'Frische Ideen und Umsetzungen für Ihren Erfolg bei Akquise, Branding und Vermarktung.'
        },
        {
          text: 'Erhalten Sie als BOTTIMMO-Kunde 10% Rabatt auf ausgewählte Services, Workshops und Produkte.'
        }
        ],
        registrationLinkText: 'Mehr dazu'
      },
      en: {
        subTitle: 'RealXLab bietet Coaching, Betreuung und Beratung für Immobilienmakler.',
        text: ['Sie benötigen es individueller oder einen Sparringspartner? Ein Feedback oder Anpassung für die optimale Immobilienmakler-Website? Einen Check/ Betreuung Ihrer (Google/Facebook) Accounts? Die Auswahl der richtigen Software? Hilfe bei der Umsetzung der BOTTIMMO-Inhalte?'],
        registrationLink: 'https://realxlab.com/bottimmo-specials',
        logo: 'img/realxlab.png',
        tags: [PARTNER_TAGS.OTHER],
        code: 'bottimmo',
        textWithLink: [{
          text: 'Profitieren Sie von über 20 Jahre Erfahrung mit Immobilienmaklern von Robert Kampczyk (RealXLab).'
        },
        {
          text: 'Frische Ideen und Umsetzungen für Ihren Erfolg bei Akquise, Branding und Vermarktung.'
        },
        {
          text: 'Erhalten Sie als BOTTIMMO-Kunde 10% Rabatt auf ausgewählte Services, Workshops und Produkte.'
        }
        ],
        registrationLinkText: 'Learn more'
      }
    },
    LOCAL_ON_TOP_PARTNER: {
      de: {
        subTitle: 'Suchmaschinenoptimierung (SEO) für Webseite und Google My Business',
        text: [`
        90 % aller Immobilienverkäufer verschaffen sich mit Google einen regionalen Überblick über Markt & Makler. Das ist Ihre Chance als lokaler Experte rund um das Thema Immobilienverkauf zu glänzen. Vorausgesetzt, Ihre Webseite wird bei den relevanten Suchbegriffen sichtbar.
        Sichern Sie sich als BOTTIMMO Kunde mit dem Gutscheincode eine exklusive, kostenfreie Sichtbarkeitsanalyse Ihrer Webseite und Ihres Google My Business Eintrages. Mit klarem Fokus auf die Immobilienwirtschaft und langjähriger Erfahrung zählt LocalOnTop zu den SEO Gipfelstürmern für Makler.`],
        registrationLink: 'https://www.localontop.de/kontakt',
        code: 'Platzhirsch',
        logo: 'img/local-on-top.png',
        tags: [PARTNER_TAGS.SEO],
        registrationLinkText: 'Mehr dazu'
      },
      en: {
        subTitle: 'Suchmaschinenoptimierung (SEO) für Webseite und Google My Business',
        text: [`
        90 % aller Immobilienverkäufer verschaffen sich mit Google einen regionalen Überblick über Markt & Makler. Das ist Ihre Chance als lokaler Experte rund um das Thema Immobilienverkauf zu glänzen. Vorausgesetzt, Ihre Webseite wird bei den relevanten Suchbegriffen sichtbar.
        Sichern Sie sich als BOTTIMMO Kunde mit dem Gutscheincode eine exklusive, kostenfreie Sichtbarkeitsanalyse Ihrer Webseite und Ihres Google My Business Eintrages. Mit klarem Fokus auf die Immobilienwirtschaft und langjähriger Erfahrung zählt LocalOnTop zu den SEO Gipfelstürmern für Makler.`],
        registrationLink: 'https://www.localontop.de/kontakt',
        code: 'Platzhirsch',
        logo: 'img/local-on-top.png',
        tags: [PARTNER_TAGS.SEO],
        registrationLinkText: 'Learn more'
      }
    },
    SINCHEN_MEDIA: {
      de: {
        subTitle: 'Individualisierung & WordPress-Integration | Corporate Design & Website',
        text: [
          'Als BOTTIMMO-Kunde verfügen Sie bereits über hochqualitative Inhalte – doch die automatisierte Individualisierung reicht Ihnen nicht? Wir verwandeln Ihre gebuchten BOTTIMMO-Inhalte in individuelle und damit authentische Produkte.',
          'Mit unserer professionellen WordPress-Integration fügen wir Ihre gebuchten BOTTIMMO-Inhalte nahtlos in ihre bestehende WordPress-Unternehmensseite ein.',
          'Das Maklerpaket "GRÜNDER" exklusiv für BOTTIMMO-Kunden bietet schließlich das volle Verwöhnprogramm für Ihren authentischen Unternehmensauftritt inklusive Corporate Design, Geschäftsausstattung und Makler-Website mit BOTTIMMO-Integration.'
        ],
        registrationLink: 'https://sinchenmedia.com/bottimmo/',
        registrationLinkText: 'Mehr dazu',
        logo: 'img/sinchenmedia.png',
        tags: [PARTNER_TAGS.WEBSITE_DEVELOPMENT]
      },
      en: {
        subTitle: 'Individualisierung & WordPress-Integration | Corporate Design & Website',
        text: [
          'Als BOTTIMMO-Kunde verfügen Sie bereits über hochqualitative Inhalte – doch die automatisierte Individualisierung reicht Ihnen nicht? Wir verwandeln Ihre gebuchten BOTTIMMO-Inhalte in individuelle und damit authentische Produkte.',
          'Mit unserer professionellen WordPress-Integration fügen wir Ihre gebuchten BOTTIMMO-Inhalte nahtlos in ihre bestehende WordPress-Unternehmensseite ein.',
          'Das Maklerpaket "GRÜNDER" exklusiv für BOTTIMMO-Kunden bietet schließlich das volle Verwöhnprogramm für Ihren authentischen Unternehmensauftritt inklusive Corporate Design, Geschäftsausstattung und Makler-Website mit BOTTIMMO-Integration.'
        ],
        registrationLink: 'https://sinchenmedia.com/bottimmo/',
        registrationLinkText: 'Learn more',
        logo: 'img/sinchenmedia.png',
        tags: [PARTNER_TAGS.WEBSITE_DEVELOPMENT]
      }
    },
    // MULTIPHONE: {
    //   subTitle: 'multiphone verwandelt Ihre Leads in einen Termin und hält Ihnen den Rücken frei.',
    //   text: ['Seit 1998 zählt multiphone zum festen Bestandteil der Immobilienbranche. Wir genießen das ununterbrochene Vertrauen von Immobilienmaklern dank einer zuverlässigen Zusammenarbeit. Wir bearbeiten täglich über 16.000 eingehende Gespräche mit einem hohen Anteil von Immobilienanbietern und Interessenten.',
    //     'Unser geschultes Akquise-Team ist ideal auf den Prozess mit BOTTIMMO eingestellt. In kürzester Zeit kontaktieren wir telefonisch Ihre neu gewonnenen Leads mit dem Ziel Termine für Sie zu generieren. Maximieren Sie die Erfolgsquote Ihrer Leads und starten Sie noch heute mit mehr Objektanbieter-Terminen!',
    //     'Sichern Sie sich jetzt Ihr Startguthaben von 25 Euro.'
    //   ],
    //   registrationLink: 'https://bottimmodeal.funnelcockpit.com/',
    //   logo: 'img/multiphone.png',
    //   tags: [PARTNER_TAGS.LEAD_QUALITY],
    //   registrationLinkText: 'Mehr dazu'
    // },
    ROCKET_SUITS: {
      de: {
        subTitle: 'Professionelle und schnelle onOffice-Website-Integration der BOTTIMMO Köder.',
        text: ['Wir integrieren Ihre persönlichen BOTTIMMO Köder nahtlos in Ihre Website. Nebst der Berücksichtigung Ihrer Zielgruppe, achten wir besonders darauf, dass alle Tools, Ratgeber und Checklisten ansprechend & barrierefrei für die Besucher Ihrer Website zugänglich sind.',
          'Bonus: Durch eine suchmaschinenoptimierte Integration der BOTTIMMO Köder, erhalten Sie eine höhere Relevanz bei Google. Profitieren Sie von mehr Reichweite und Aufrufe Ihrer Website, sodass Sie die BOTTIMMO Köder noch effektiver nutzen können.'
        ],
        registrationLink: 'https://bottimmo.rocketsuits.de',
        logo: 'img/rocketsuits.png',
        tags: [PARTNER_TAGS.WEBSITE_DEVELOPMENT],
        registrationLinkText: 'Mehr dazu'
      },
      en: {
        subTitle: 'Professionelle und schnelle onOffice-Website-Integration der BOTTIMMO Köder.',
        text: ['Wir integrieren Ihre persönlichen BOTTIMMO Köder nahtlos in Ihre Website. Nebst der Berücksichtigung Ihrer Zielgruppe, achten wir besonders darauf, dass alle Tools, Ratgeber und Checklisten ansprechend & barrierefrei für die Besucher Ihrer Website zugänglich sind.',
          'Bonus: Durch eine suchmaschinenoptimierte Integration der BOTTIMMO Köder, erhalten Sie eine höhere Relevanz bei Google. Profitieren Sie von mehr Reichweite und Aufrufe Ihrer Website, sodass Sie die BOTTIMMO Köder noch effektiver nutzen können.'
        ],
        registrationLink: 'https://bottimmo.rocketsuits.de',
        logo: 'img/rocketsuits.png',
        tags: [PARTNER_TAGS.WEBSITE_DEVELOPMENT],
        registrationLinkText: 'Learn more'
      }
    },
    BEWERTUNGSLOESCHER: {
      de: {
        subTitle: 'Die Bewertungslöscher sind Ihr Partner, wenn Sie eine ungerechtfertigte Bewertung erhalten haben!',
        text: ['In nur drei Schritten können Sie sich von imagefeindlichen Rezensionen verabschieden.',
          'Nutzen Sie die kostenlose Erstberatung, um zu erfahren, ob Ihre negativen Bewertungen entfernt werden können.',
          'Anschließend lehnen Sie sich zurück - spezialisierte Fachanwälte leiten für Sie das Widerspruchsverfahren bei dem zuständigen Bewertungsportal ein.',
          'Als BOTTIMMO Kunde profitieren Sie von 15% Rabatt auf unseren Service.'
        ],
        registrationLink: 'https://bewertungsloescher.de/lp-loeschung-pruefen-2/',
        logo: 'img/bewertungsloescher.jpg',
        tags: [PARTNER_TAGS.FEEDBACK_MANAGEMENT],
        code: 'bottimmo15',
        registrationLinkText: 'Mehr dazu'
      },
      en: {
        subTitle: 'Die Bewertungslöscher sind Ihr Partner, wenn Sie eine ungerechtfertigte Bewertung erhalten haben!',
        text: ['In nur drei Schritten können Sie sich von imagefeindlichen Rezensionen verabschieden.',
          'Nutzen Sie die kostenlose Erstberatung, um zu erfahren, ob Ihre negativen Bewertungen entfernt werden können.',
          'Anschließend lehnen Sie sich zurück - spezialisierte Fachanwälte leiten für Sie das Widerspruchsverfahren bei dem zuständigen Bewertungsportal ein.',
          'Als BOTTIMMO Kunde profitieren Sie von 15% Rabatt auf unseren Service.'
        ],
        registrationLink: 'https://bewertungsloescher.de/lp-loeschung-pruefen-2/',
        logo: 'img/bewertungsloescher.jpg',
        tags: [PARTNER_TAGS.FEEDBACK_MANAGEMENT],
        code: 'bottimmo15',
        registrationLinkText: 'Learn more'
      }
    },
    STORYBOX: {
      de: {
        subTitle: 'Mit der StoryBox-App schnell und einfach Videobotschaften selber aufnehmen.',
        text: [
          'Ab heute ist die Produktion von authentischen Videoaufnahmen kinderleicht und kosteneffizient. Nutzen Sie die StoryBox-App, um anhand von fertigen Templates hochwertige Videos aufzunehmen, zu veröffentlichen und zu teilen. Gewinnen Sie Reichweite und zeigen Sie Ihre Kompetenz und Mehrwert in der bestmöglichen Form. ',
          'Sichern Sie sich jetzt das BOTTIMMO-StoryBox-Paket mit 4 exklusiven BOTTIMMO-Video-Vorlagen für Ihre erfolgreiche Immobilien-Akquise.'
        ],
        registrationLink: 'https://storybox.cloud/bottimmo/',
        logo: 'img/storybox.png',
        tags: [PARTNER_TAGS.CONTENT],
        code: 'BOTTIMMO',
        registrationLinkText: 'Mehr dazu'
      },
      en: {
        subTitle: 'Mit der StoryBox-App schnell und einfach Videobotschaften selber aufnehmen.',
        text: [
          'Ab heute ist die Produktion von authentischen Videoaufnahmen kinderleicht und kosteneffizient. Nutzen Sie die StoryBox-App, um anhand von fertigen Templates hochwertige Videos aufzunehmen, zu veröffentlichen und zu teilen. Gewinnen Sie Reichweite und zeigen Sie Ihre Kompetenz und Mehrwert in der bestmöglichen Form. ',
          'Sichern Sie sich jetzt das BOTTIMMO-StoryBox-Paket mit 4 exklusiven BOTTIMMO-Video-Vorlagen für Ihre erfolgreiche Immobilien-Akquise.'
        ],
        registrationLink: 'https://storybox.cloud/bottimmo/',
        logo: 'img/storybox.png',
        tags: [PARTNER_TAGS.CONTENT],
        code: 'BOTTIMMO',
        registrationLinkText: 'Learn more'
      }
    },
    CLICKLIFT: {
      de: {
        subTitle: 'Durch unsere Leistungen aus dem Bereich Online-Marketing erhalten Sie die besten Leads für Ihre BOTTIMMO-App.',
        text: [
          'Werden Sie mit professionellem Online-Marketing zum Platzhirsch in Ihrer Region. Mehr Besucher auf Ihrer Website, mehr Sichtbarkeit Ihrer Marke. Erhalten Sie als wahrgenommene Nummer eins die besten Alleinaufträge.',
          'Egal, ob SEO, SEA, CRO, SMM u.v.m.: Setzen Sie auf die perfekte Online-Marketing-Strategie, denn wir sprechen die Sprache Ihrer Zielgruppe. Bei uns erhalten Sie eine offene und ehrliche Kosten- und Vertragspolitik.',
          'Buchen Sie noch heute mit Ihrem exklusivem Gutschein Ihr kostenfreies Analyse- und Beratungsgespräch.'
        ],
        registrationLink: 'https://www.clicklift.de/immobilienmakler/',
        logo: 'img/clicklift.png',
        tags: [PARTNER_TAGS.SEO],
        code: '77#Mmo',
        registrationLinkText: 'Mehr dazu'
      },
      en: {
        subTitle: 'Durch unsere Leistungen aus dem Bereich Online-Marketing erhalten Sie die besten Leads für Ihre BOTTIMMO-App.',
        text: [
          'Werden Sie mit professionellem Online-Marketing zum Platzhirsch in Ihrer Region. Mehr Besucher auf Ihrer Website, mehr Sichtbarkeit Ihrer Marke. Erhalten Sie als wahrgenommene Nummer eins die besten Alleinaufträge.',
          'Egal, ob SEO, SEA, CRO, SMM u.v.m.: Setzen Sie auf die perfekte Online-Marketing-Strategie, denn wir sprechen die Sprache Ihrer Zielgruppe. Bei uns erhalten Sie eine offene und ehrliche Kosten- und Vertragspolitik.',
          'Buchen Sie noch heute mit Ihrem exklusivem Gutschein Ihr kostenfreies Analyse- und Beratungsgespräch.'
        ],
        registrationLink: 'https://www.clicklift.de/immobilienmakler/',
        logo: 'img/clicklift.png',
        tags: [PARTNER_TAGS.SEO],
        code: '77#Mmo',
        registrationLinkText: 'Learn more'
      }
    },
    IMOGENT: {
      de: {
        subTitle: 'IMOGENT vereint alle Leistungen für die Immobilienvermarktung an einem Ort.',
        text: [
          'IMOGENT ermöglicht Immobilienvermarktern die zeitaufwendige Beschaffung verschiedener externer Vermarktungsleistungen und Software-Tools über eine zentrale Plattform abzuwickeln.',
          'Zu diesen Leistungen zählen unter anderem Visualisierungen (Renderings), Grundrisse, Energieausweise, Behördenauskünfte und vieles mehr.',
          'Prozesse, die unter normalen Umständen mit hohem Zeitaufwand koordiniert werden müssen, können auf der IMOGENT-Plattform mit wenigen Klicks online bestellt werden.',
          'Als BOTTIMMO-Kunde erhalten Sie mit diesem Gutschein-Code 15 Prozent Rabatt auf die erste Bestellung.'
        ],
        registrationLink: 'https://www.imogent.de/immobilienvermarktung-bottimmo/?mtm_campaign=bottimmo-lp',
        logo: 'img/imogent.png',
        tags: [PARTNER_TAGS.OTHER],
        code: 'BOTTIMMO15',
        registrationLinkText: 'Mehr dazu'
      },
      en: {
        subTitle: 'IMOGENT vereint alle Leistungen für die Immobilienvermarktung an einem Ort.',
        text: [
          'IMOGENT ermöglicht Immobilienvermarktern die zeitaufwendige Beschaffung verschiedener externer Vermarktungsleistungen und Software-Tools über eine zentrale Plattform abzuwickeln.',
          'Zu diesen Leistungen zählen unter anderem Visualisierungen (Renderings), Grundrisse, Energieausweise, Behördenauskünfte und vieles mehr.',
          'Prozesse, die unter normalen Umständen mit hohem Zeitaufwand koordiniert werden müssen, können auf der IMOGENT-Plattform mit wenigen Klicks online bestellt werden.',
          'Als BOTTIMMO-Kunde erhalten Sie mit diesem Gutschein-Code 15 Prozent Rabatt auf die erste Bestellung.'
        ],
        registrationLink: 'https://www.imogent.de/immobilienvermarktung-bottimmo/?mtm_campaign=bottimmo-lp',
        logo: 'img/imogent.png',
        tags: [PARTNER_TAGS.OTHER],
        code: 'BOTTIMMO15',
        registrationLinkText: 'Learn more'
      }
    },
    FIFY: {
      de: {
        subTitle: 'Moderne, schnelle Websites, entwickelt für Immobilienprofis.',
        text: [
          'Fify erstellt erstaunlich schnelle, kostengünstige Websites in Designs, die Kunden und Suchmaschinen lieben.',
          'Um den Besuchern Ihrer Website digitale Erlebnisse zu bereiten und ausgezeichneten Service zu bieten, ist ein professionelles Webdesign eine essenzielle Basis. fify Websites sind für Kunden, die hohe Ansprüche an Design und Funktionalität haben. ',
          'Als BOTTIMMO Kunde ist der erste Monat für Sie kostenfrei. Sie sparen 99,- EUR.'
        ],
        registrationLink: 'https://fify.website/de/bottimmo',
        logo: 'img/fify.png',
        tags: [PARTNER_TAGS.WEBSITE_DEVELOPMENT],
        registrationLinkText: 'Mehr dazu'
      },
      en: {
        subTitle: 'Moderne, schnelle Websites, entwickelt für Immobilienprofis.',
        text: [
          'Fify erstellt erstaunlich schnelle, kostengünstige Websites in Designs, die Kunden und Suchmaschinen lieben.',
          'Um den Besuchern Ihrer Website digitale Erlebnisse zu bereiten und ausgezeichneten Service zu bieten, ist ein professionelles Webdesign eine essenzielle Basis. fify Websites sind für Kunden, die hohe Ansprüche an Design und Funktionalität haben. ',
          'Als BOTTIMMO Kunde ist der erste Monat für Sie kostenfrei. Sie sparen 99,- EUR.'
        ],
        registrationLink: 'https://fify.website/de/bottimmo',
        logo: 'img/fify.png',
        tags: [PARTNER_TAGS.WEBSITE_DEVELOPMENT],
        registrationLinkText: 'Learn more'
      }
    },
    MECOA: {
      de: {
        subTitle: 'POST IT – Der Social Media Online-Kurs für ImmobilienmaklerInnen.',
        text: [
          'Erfahren Sie, wie Sie Schritt für Schritt mit einer individuellen Strategie Ihre Bekanntheit steigern und neue Interessenten auf sich aufmerksam machen. Mit unseren Strategien, Anleitungen und ready-to-post Content-Vorlagen zeigen wir Ihnen, wie Sie zeitsparend und effektiv auf Social Media präsent sein können. Melden Sie sich jetzt für den Onlinekurs POST IT an und sichern Sie sich -10%!'
        ],
        registrationLink: 'https://immo.mecoa.de/post-it/',
        logo: 'img/mecoa.png',
        tags: [PARTNER_TAGS.SOCIAL_MEDIA],
        registrationLinkText: 'Mehr dazu'
      },
      en: {
        subTitle: 'POST IT – Der Social Media Online-Kurs für ImmobilienmaklerInnen.',
        text: [
          'Erfahren Sie, wie Sie Schritt für Schritt mit einer individuellen Strategie Ihre Bekanntheit steigern und neue Interessenten auf sich aufmerksam machen. Mit unseren Strategien, Anleitungen und ready-to-post Content-Vorlagen zeigen wir Ihnen, wie Sie zeitsparend und effektiv auf Social Media präsent sein können. Melden Sie sich jetzt für den Onlinekurs POST IT an und sichern Sie sich -10%!'
        ],
        registrationLink: 'https://immo.mecoa.de/post-it/',
        logo: 'img/mecoa.png',
        tags: [PARTNER_TAGS.SOCIAL_MEDIA],
        registrationLinkText: 'Learn more'
      }
    },
    IMA_MARKETREPORT: {
      de: {
        subTitle: 'Mit modernen Marktberichten zur Expertenpositionierung in Ihrer Farm.',
        text: [
          'Nichts veranschaulicht die lokale Marktführerschaft besser als ein eigener Marktbericht mit professionell aufbereiteten Marktdaten der Region. Eingebettet in Ihr BOTTIMMO System, erhalten Sie so einen weiteren Lead-Köder für Ihre Immobilien-Akquise-Strategie.',
          'Als BOTTIMMO Kunde profitieren Sie von 10% Rabatt auf unseren Service. Jetzt Termin vereinbaren und Marktbericht konfigurieren lassen.'
        ],
        registrationLink: 'https://link.maklersprechstunde.com/marktberichtbottimmo',
        logo: 'img/immobilien-makler-akademie-marktbericht.png',
        tags: [PARTNER_TAGS.DATA],
        registrationLinkText: 'Mehr dazu'
      },
      en: {
        subTitle: 'Mit modernen Marktberichten zur Expertenpositionierung in Ihrer Farm.',
        text: [
          'Nichts veranschaulicht die lokale Marktführerschaft besser als ein eigener Marktbericht mit professionell aufbereiteten Marktdaten der Region. Eingebettet in Ihr BOTTIMMO System, erhalten Sie so einen weiteren Lead-Köder für Ihre Immobilien-Akquise-Strategie.',
          'Als BOTTIMMO Kunde profitieren Sie von 10% Rabatt auf unseren Service. Jetzt Termin vereinbaren und Marktbericht konfigurieren lassen.'
        ],
        registrationLink: 'https://link.maklersprechstunde.com/marktberichtbottimmo',
        logo: 'img/immobilien-makler-akademie-marktbericht.png',
        tags: [PARTNER_TAGS.DATA],
        registrationLinkText: 'Learn more'
      }
    },
    VON_FRED: {
      de: {
        subTitle: 'Professionelle Social-Media-Werbung für mehr Bekanntheit und Leads in Ihrer Farm!',
        text: [
          'Als Premium-Partner von BOTTIMMO ist Ihr Social-Media-Marketing bei von Fred richtig gut aufgehoben. Mit bezahlten Anzeigen auf Facebook und Instagram generieren Sie Sichtbarkeit in Ihrer Farm und sammeln marketingqualifizierte Leads von potenziellen Verkäufern ein.',
          'Profitieren Sie als BOTTIMMO-Kunde von 15 % Rabatt auf das Lead-Paket und vereinbaren Sie ein erstes unverbindliches Beratungsgespräch! #undlos'
        ],
        registrationLink: 'https://von-fred.de/immobilienmakler/',
        logo: 'img/von-fred.png',
        tags: [PARTNER_TAGS.SOCIAL_MEDIA],
        registrationLinkText: 'Mehr dazu'
      },
      en: {
        subTitle: 'Professionelle Social-Media-Werbung für mehr Bekanntheit und Leads in Ihrer Farm!',
        text: [
          'Als Premium-Partner von BOTTIMMO ist Ihr Social-Media-Marketing bei von Fred richtig gut aufgehoben. Mit bezahlten Anzeigen auf Facebook und Instagram generieren Sie Sichtbarkeit in Ihrer Farm und sammeln marketingqualifizierte Leads von potenziellen Verkäufern ein.',
          'Profitieren Sie als BOTTIMMO-Kunde von 15 % Rabatt auf das Lead-Paket und vereinbaren Sie ein erstes unverbindliches Beratungsgespräch! #undlos'
        ],
        registrationLink: 'https://von-fred.de/immobilienmakler/',
        logo: 'img/von-fred.png',
        tags: [PARTNER_TAGS.SOCIAL_MEDIA],
        registrationLinkText: 'Learn more'
      }
    },
    REDCUP: {
      de: {
        subTitle: 'Professionelle Google Werbung mit Google Premium Partner Red Cup.',
        text: [
          'Wir schalten für Sie Google Werbeanzeigen und sorgen so für mehr Dominanz bei der Suchergebnisseite, mehr Sichtbarkeit in Ihrer Farm und einer gut gefüllte BOTTIMMO-App.',
          'Mit uns holen Sie sich eine der top 3% von Google ausgezeichneten deutschen Agenturen ins Boot. Unsere Expertise rund um Google und den Immobilienmarkt bildet Ihr Erfolgsrezept.',
          'Profitieren Sie von vergünstigten BOTTIMMO-Konditionen und einem 400 EUR Werbebudget-Gutschein.'
        ],
        registrationLink: 'https://red.cup.agency/bottimmo-partner/',
        logo: 'img/redcup.png',
        tags: [PARTNER_TAGS.SEO],
        registrationLinkText: 'Mehr dazu'
      },
      en: {
        subTitle: 'Professionelle Google Werbung mit Google Premium Partner Red Cup.',
        text: [
          'Wir schalten für Sie Google Werbeanzeigen und sorgen so für mehr Dominanz bei der Suchergebnisseite, mehr Sichtbarkeit in Ihrer Farm und einer gut gefüllte BOTTIMMO-App.',
          'Mit uns holen Sie sich eine der top 3% von Google ausgezeichneten deutschen Agenturen ins Boot. Unsere Expertise rund um Google und den Immobilienmarkt bildet Ihr Erfolgsrezept.',
          'Profitieren Sie von vergünstigten BOTTIMMO-Konditionen und einem 400 EUR Werbebudget-Gutschein.'
        ],
        registrationLink: 'https://red.cup.agency/bottimmo-partner/',
        logo: 'img/redcup.png',
        tags: [PARTNER_TAGS.SEO],
        registrationLinkText: 'Learn more'
      }
    },
    SOLOWERK: {
      de: {
        subTitle: 'solowerk – die Kreativ-Agentur für Immobilienmakler*innen.',
        text: [
          'Mit unseren prägnanten Texten, guten Konzepten und innovativen Grafiken zu mehr Vielfalt im Markenauftritt. Als Boutique-Agentur für die Immobilienbranche setzen wir auf individuelle Werbemittel und Produkte im Printbereich und das seit 2005. Wir erarbeiten nicht nur individuelle Ideen für Sie, wir passen auch gerne vorhandene BOTTIMMO-Ratgeber und Co. mehr an Ihr Design an.',
          'Speziell für BOTTIMMO Kund*innen haben wir ein Facebook-Posting-Paket inkl. durchdachten Redaktionsplan, liebevollen Posts und passenden Texten kreiert. Mit Ihrem Gutschein-Code erhalten Sie ein Facebook Profil-Makeover gratis dazu!'
        ],
        registrationLink: 'https://www.solowerk.com/social-paket/',
        logo: 'img/solowerk.png',
        tags: [PARTNER_TAGS.CONTENT],
        registrationLinkText: 'Mehr dazu'
      },
      en: {
        subTitle: 'solowerk – die Kreativ-Agentur für Immobilienmakler*innen.',
        text: [
          'Mit unseren prägnanten Texten, guten Konzepten und innovativen Grafiken zu mehr Vielfalt im Markenauftritt. Als Boutique-Agentur für die Immobilienbranche setzen wir auf individuelle Werbemittel und Produkte im Printbereich und das seit 2005. Wir erarbeiten nicht nur individuelle Ideen für Sie, wir passen auch gerne vorhandene BOTTIMMO-Ratgeber und Co. mehr an Ihr Design an.',
          'Speziell für BOTTIMMO Kund*innen haben wir ein Facebook-Posting-Paket inkl. durchdachten Redaktionsplan, liebevollen Posts und passenden Texten kreiert. Mit Ihrem Gutschein-Code erhalten Sie ein Facebook Profil-Makeover gratis dazu!'
        ],
        registrationLink: 'https://www.solowerk.com/social-paket/',
        logo: 'img/solowerk.png',
        tags: [PARTNER_TAGS.CONTENT],
        registrationLinkText: 'Learn more'
      }
    },
    PIXELWERKSTATT: {
      de: {
        subTitle: 'Website-Integration der BOTTIMMO Leadmagneten.',
        text: ['Wir sind Ihr Partner für den Einbau Ihrer BOTTIMMO Tools, Ratgeber und Checklisten.',
          'Schnell, fachmännisch und zuverlässig in der Umsetzung legen wir nicht nur Wert auf optimale Präsenz, sondern berücksichtigen auch wichtige Themen wie bspw. Google Sichtbarkeit, Tracking und die DSGVO.',
          'Kostenloser SEO Check Ihrer Seite inklusive - wir freuen uns auf Ihren Kontakt.'],
        registrationLink: 'https://www.localontop.de/bottimmo-integration',
        logo: 'img/pixelwerkstatt.png',
        tags: [PARTNER_TAGS.WEBSITE_DEVELOPMENT],
        registrationLinkText: 'Mehr dazu'
      },
      en: {
        subTitle: 'Website-Integration der BOTTIMMO Leadmagneten.',
        text: ['Wir sind Ihr Partner für den Einbau Ihrer BOTTIMMO Tools, Ratgeber und Checklisten.',
          'Schnell, fachmännisch und zuverlässig in der Umsetzung legen wir nicht nur Wert auf optimale Präsenz, sondern berücksichtigen auch wichtige Themen wie bspw. Google Sichtbarkeit, Tracking und die DSGVO.',
          'Kostenloser SEO Check Ihrer Seite inklusive - wir freuen uns auf Ihren Kontakt.'],
        registrationLink: 'https://www.localontop.de/bottimmo-integration',
        logo: 'img/pixelwerkstatt.png',
        tags: [PARTNER_TAGS.WEBSITE_DEVELOPMENT],
        registrationLinkText: 'Learn more'
      }
    },
    JACASA: {
      de: {
        subTitle: 'Die Bewertungsplattform für Makler. Reichweite und Reputation mit Ihrem Firmenprofil steigern.',
        text: [`Als Premium-Partner von BOTTIMMO listet Jacasa kostenlos über 24.000 Maklerbüros. Wertige Firmenprofile steigern Ihre Reputation, indem Expertise, Leistungen und Inserate auf einen Blick präsentiert werden. Dadurch generieren Sie mehr Eigentümer und Kaufinteressenten zu Kontaktanfragen.
        Steigern Sie jetzt Ihre Sichtbarkeit gegenüber Interessenten, die einen Makler in der Nähe recherchieren.`],
        registrationLink: 'https://www.jacasa.de/vorteile',
        logo: 'img/jacasa.png',
        tags: [PARTNER_TAGS.OTHER]
      },
      en: {
        subTitle: 'Die Bewertungsplattform für Makler. Reichweite und Reputation mit Ihrem Firmenprofil steigern.',
        text: [`Als Premium-Partner von BOTTIMMO listet Jacasa kostenlos über 24.000 Maklerbüros. Wertige Firmenprofile steigern Ihre Reputation, indem Expertise, Leistungen und Inserate auf einen Blick präsentiert werden. Dadurch generieren Sie mehr Eigentümer und Kaufinteressenten zu Kontaktanfragen.
        Steigern Sie jetzt Ihre Sichtbarkeit gegenüber Interessenten, die einen Makler in der Nähe recherchieren.`],
        registrationLink: 'https://www.jacasa.de/vorteile',
        logo: 'img/jacasa.png',
        tags: [PARTNER_TAGS.OTHER]
      }
    },
    IMMOVIEWER: {
      de: {
        subTitle: '360° Software von immoviewer – mehr als nur 3D',
        text: [`Sie sind bereits Teil der Marktführer-Community von BOTTIMMO und es ist an der Zeit, sich dem Spitzenreiter in Sachen 360° Rundgängen und Neubau-Visualisierungen anzuschließen: immoviewer. Unsere 360-Grad-Software und 3D-Rundgänge ermöglichen es Ihnen, Immobilien auf einfache, effiziente und kostengünstige Weise zu erstellen und zu präsentieren.
        Sie erhalten eine umfassende Suite von Funktionen, die Ihr Immobiliengeschäft auf ein neues Niveau heben.`,
        'Als Dankeschön für Ihre Treue bieten wir allen BOTTIMMO-Kunden exklusive Rabatte von bis zu 50 % auf unsere Dienstleistungen.'],
        registrationLink: 'https://www.immoviewer.de/',
        logo: 'img/immoviewer.png',
        tags: [PARTNER_TAGS.CONTENT],
        code: 'BOTTIMMO',
        registrationLinkText: 'Mehr dazu'
      },
      en: {
        subTitle: '360° Software von immoviewer – mehr als nur 3D',
        text: [`Sie sind bereits Teil der Marktführer-Community von BOTTIMMO und es ist an der Zeit, sich dem Spitzenreiter in Sachen 360° Rundgängen und Neubau-Visualisierungen anzuschließen: immoviewer. Unsere 360-Grad-Software und 3D-Rundgänge ermöglichen es Ihnen, Immobilien auf einfache, effiziente und kostengünstige Weise zu erstellen und zu präsentieren.
        Sie erhalten eine umfassende Suite von Funktionen, die Ihr Immobiliengeschäft auf ein neues Niveau heben.`,
        'Als Dankeschön für Ihre Treue bieten wir allen BOTTIMMO-Kunden exklusive Rabatte von bis zu 50 % auf unsere Dienstleistungen.'],
        registrationLink: 'https://www.immoviewer.de/',
        logo: 'img/immoviewer.png',
        tags: [PARTNER_TAGS.CONTENT],
        code: 'BOTTIMMO',
        registrationLinkText: 'Learn more'
      }
    },
    FPRE: {
      de: {
        subTitle: 'Erstellen Sie Analysen und Bewertungen - Marktintelligenz für Immobilienakteure. Datenbasiert und forschungsnah.',
        text: [
          'Nutzen Sie die Webapplikation IMBAS von Fahrländer Partner Raumentwicklung für evidenzbasierte und datengetriebene Immobilien-Entscheide. Erstellen Sie fundierte Standortanalysen, Bewertungen und Portfolioanalysen per Knopfdruck.',
          'IMBAS kann flächendeckend in Deutschland und der Schweiz eingesetzt werden.',
          'Fordern Sie einen vollumfänglichen, unverbindlichen und kostenlosen IMBAS-Testzugang an und überzeugen Sie Ihre Kunden mit umfassenden Analysen und Gutachten. '
        ],
        registrationLink: 'https://fpre.ch/willkommen-bei-fpre/',
        registrationLinkText: 'Mehr dazu',
        logo: 'img/fpre.jpg',
        tags: [PARTNER_TAGS.DATA]
      },
      en: {
        subTitle: 'Erstellen Sie Analysen und Bewertungen - Marktintelligenz für Immobilienakteure. Datenbasiert und forschungsnah.',
        text: [
          'Nutzen Sie die Webapplikation IMBAS von Fahrländer Partner Raumentwicklung für evidenzbasierte und datengetriebene Immobilien-Entscheide. Erstellen Sie fundierte Standortanalysen, Bewertungen und Portfolioanalysen per Knopfdruck.',
          'IMBAS kann flächendeckend in Deutschland und der Schweiz eingesetzt werden.',
          'Fordern Sie einen vollumfänglichen, unverbindlichen und kostenlosen IMBAS-Testzugang an und überzeugen Sie Ihre Kunden mit umfassenden Analysen und Gutachten. '
        ],
        registrationLink: 'https://fpre.ch/willkommen-bei-fpre/',
        registrationLinkText: 'Learn more',
        logo: 'img/fpre.jpg',
        tags: [PARTNER_TAGS.DATA]
      }
    },
    REGIONALIMMOBILIEN: {
      de: {
        subTitle: 'Erhöhen Sie Ihre Reichweite mit unserem regionalen Immobilienportal',
        text: [
          'Nutzen Sie unser regionales Immobilienportal für mehr Sichtbarkeit in Ihrer Stadt und stärken Sie Ihre Unabhängigkeit als Makler. Im Basic-Paket inserieren Sie alle Ihre Immobilien kostenfrei. Im Premium-Paket profitieren Sie 6 Monate lang ohne Gebühren von unserem Angebot.',
          'Kontaktieren Sie uns für mehr Details und starten Sie jetzt damit, Ihre Präsenz zu optimieren.'
        ],
        registrationLink: 'https://www.regionalimmobilien24.de/immobilienportal-partner-werden/bottimmo/',
        registrationLinkText: 'Mehr dazu',
        logo: 'img/regionalimmobilien.png',
        code: 'BOTTIMMO',
        tags: [PARTNER_TAGS.OTHER]
      },
      en: {
        subTitle: 'Erhöhen Sie Ihre Reichweite mit unserem regionalen Immobilienportal',
        text: [
          'Nutzen Sie unser regionales Immobilienportal für mehr Sichtbarkeit in Ihrer Stadt und stärken Sie Ihre Unabhängigkeit als Makler. Im Basic-Paket inserieren Sie alle Ihre Immobilien kostenfrei. Im Premium-Paket profitieren Sie 6 Monate lang ohne Gebühren von unserem Angebot.',
          'Kontaktieren Sie uns für mehr Details und starten Sie jetzt damit, Ihre Präsenz zu optimieren.'
        ],
        registrationLink: 'https://www.regionalimmobilien24.de/immobilienportal-partner-werden/bottimmo/',
        registrationLinkText: 'Mehr dazu',
        logo: 'img/regionalimmobilien.png',
        code: 'BOTTIMMO',
        tags: [PARTNER_TAGS.OTHER]
      }
    }
  },
  remax: {
    VON_FRED: {
      de: {
        subTitle: 'Professionelle Social-Media-Werbung für mehr Bekanntheit und Leads in Ihrer Farm!',
        text: [
          'Als Premium-Partner des RE/MAX Lead Turbo ist Ihr Social-Media-Marketing bei von Fred richtig gut aufgehoben. Mit bezahlten Anzeigen auf Facebook und Instagram generieren Sie Sichtbarkeit in Ihrer Farm und sammeln marketingqualifizierte Leads von potenziellen Verkäufern ein.',
          'Profitieren Sie als RE/MAX Makler:in von 15 % Rabatt auf das Lead-Paket und vereinbaren Sie ein erstes unverbindliches Beratungsgespräch! #undlos',
          'Beratung auf Stundenbasis oder Betreuungs-Paket ab 432 EUR/ Monat*',
          '*Preise netto; zzgl. anfallender Einrichtungsgebühr'
        ],
        registrationLink: 'https://von-fred.de/remax/',
        logo: 'img/von-fred.png',
        tags: [PARTNER_TAGS.SOCIAL_MEDIA],
        registrationLinkText: 'Mehr dazu'
      },
      en: {
        subTitle: 'Professionelle Social-Media-Werbung für mehr Bekanntheit und Leads in Ihrer Farm!',
        text: [
          'Als Premium-Partner des RE/MAX Lead Turbo ist Ihr Social-Media-Marketing bei von Fred richtig gut aufgehoben. Mit bezahlten Anzeigen auf Facebook und Instagram generieren Sie Sichtbarkeit in Ihrer Farm und sammeln marketingqualifizierte Leads von potenziellen Verkäufern ein.',
          'Profitieren Sie als RE/MAX Makler:in von 15 % Rabatt auf das Lead-Paket und vereinbaren Sie ein erstes unverbindliches Beratungsgespräch! #undlos',
          'Beratung auf Stundenbasis oder Betreuungs-Paket ab 432 EUR/ Monat*',
          '*Preise netto; zzgl. anfallender Einrichtungsgebühr'
        ],
        registrationLink: 'https://von-fred.de/remax/',
        logo: 'img/von-fred.png',
        tags: [PARTNER_TAGS.SOCIAL_MEDIA],
        registrationLinkText: 'Learn more'
      }
    },
    REDCUP: {
      de: {
        subTitle: 'Professionelle Google Werbung mit Google Premium Partner Red Cup.',
        text: [
          'Wir schalten für Sie Google Werbeanzeigen und sorgen so für mehr Dominanz bei der Suchergebnisseite, mehr Sichtbarkeit in Ihrer Farm und einen gut gefüllten Lead Turbo.',
          'Mit uns holen Sie sich eine der top 3% von Google ausgezeichneten deutschen Agenturen ins Boot. Unsere Expertise rund um Google und den Immobilienmarkt bildet Ihr Erfolgsrezept.',
          'Profitieren Sie von vergünstigten RE/MAX Lead Turbo Konditionen und einem 400 EUR Werbebudget-Gutschein.',
          'Beratung auf Stundenbasis oder Betreuungs-Paket ab 399 EUR/ Monat*',
          '*Preise netto; zzgl. anfallender Einrichtungsgebühr'
        ],
        registrationLink: 'https://red.cup.agency/remax-lead-turbo/',
        logo: 'img/redcup.png',
        tags: [PARTNER_TAGS.SEO],
        registrationLinkText: 'Mehr dazu'
      },
      en: {
        subTitle: 'Professionelle Google Werbung mit Google Premium Partner Red Cup.',
        text: [
          'Wir schalten für Sie Google Werbeanzeigen und sorgen so für mehr Dominanz bei der Suchergebnisseite, mehr Sichtbarkeit in Ihrer Farm und einen gut gefüllten Lead Turbo.',
          'Mit uns holen Sie sich eine der top 3% von Google ausgezeichneten deutschen Agenturen ins Boot. Unsere Expertise rund um Google und den Immobilienmarkt bildet Ihr Erfolgsrezept.',
          'Profitieren Sie von vergünstigten RE/MAX Lead Turbo Konditionen und einem 400 EUR Werbebudget-Gutschein.',
          'Beratung auf Stundenbasis oder Betreuungs-Paket ab 399 EUR/ Monat*',
          '*Preise netto; zzgl. anfallender Einrichtungsgebühr'
        ],
        registrationLink: 'https://red.cup.agency/remax-lead-turbo/',
        logo: 'img/redcup.png',
        tags: [PARTNER_TAGS.SEO],
        registrationLinkText: 'Learn more'
      }
    },
    MAXX_CAPITAL: {
      de: {
        subTitle: 'Der Immobilienteilverkauf mit MAXXcapital.',
        text: [
          '- Teilverkauf gegen Sofortauszahlung',
          '- Wohnen bleiben und entspannen',
          '- Flexibel verkaufen oder vererben'
        ],
        registrationLink: 'https://maxx-capital.com/',
        registrationLinkText: 'Mehr dazu',
        logo: 'img/maxxcapital.png',
        tags: [PARTNER_TAGS.OTHER]
      },
      en: {
        subTitle: 'Der Immobilienteilverkauf mit MAXXcapital.',
        text: [
          '- Teilverkauf gegen Sofortauszahlung',
          '- Wohnen bleiben und entspannen',
          '- Flexibel verkaufen oder vererben'
        ],
        registrationLink: 'https://maxx-capital.com/',
        registrationLinkText: 'Learn more',
        logo: 'img/maxxcapital.png',
        tags: [PARTNER_TAGS.OTHER]
      }
    },
    MAXX_FINANCE: {
      de: {
        subTitle: 'Der Weg zur eigenen Traumimmobilie mit MAXXfinance.',
        text: [
          'Der zuverlässiger Partner für eine erfolgreiche und maßgeschneiderte Immobilienfinanzierung.'
        ],
        registrationLink: 'https://maxx-finance.com/',
        registrationLinkText: 'Mehr dazu',
        logo: 'img/maxxfinance.png',
        tags: [PARTNER_TAGS.OTHER]
      },
      en: {
        subTitle: 'Der Weg zur eigenen Traumimmobilie mit MAXXfinance.',
        text: [
          'Der zuverlässiger Partner für eine erfolgreiche und maßgeschneiderte Immobilienfinanzierung.'
        ],
        registrationLink: 'https://maxx-finance.com/',
        registrationLinkText: 'Learn more',
        logo: 'img/maxxfinance.png',
        tags: [PARTNER_TAGS.OTHER]
      }
    },
    LOCAL_ON_TOP_PARTNER: {
      de: {
        subTitle: 'Eigentümerkontakte durch Suchmaschinenoptimierung – und das OHNE Werbeanzeigen.',
        text: [`
        90% aller Immobilienverkäufer verschaffen sich mit Google einen regionalen Überblick über Markt und Makler. Oft ist der Sachverhalt aber komplexer als gedacht – das ist Ihre Chance als lokaler Experte rund um das Thema Immobilienverkauf zu glänzen. Vorausgesetzt, Ihre Webseite wird bei den relevanten Suchbegriffen sichtbar.
        Wie weit oben werden Sie aktuell bei Google gelistet? Sichern Sie sich als RE/MAX-Makler jetzt eine exklusive, kostenfreie Sichtbarkeitsanalyse Ihrer Webseite und Ihres Google My Business Eintrages.
        Mit klarem Fokus auf die Immobilienwirtschaft, langjähriger Erfahrung und herausragenden Ergebnissen in konkurrenzstarken Gebieten zählt LocalOnTop zu den SEO-Gipfelstürmern für Makler.`],
        registrationLink: 'https://www.localontop.de/remax-lead-turbo',
        logo: 'img/local-on-top.png',
        tags: [PARTNER_TAGS.SEO],
        registrationLinkText: 'Mehr dazu'
      },
      en: {
        subTitle: 'Eigentümerkontakte durch Suchmaschinenoptimierung – und das OHNE Werbeanzeigen.',
        text: [`
        90% aller Immobilienverkäufer verschaffen sich mit Google einen regionalen Überblick über Markt und Makler. Oft ist der Sachverhalt aber komplexer als gedacht – das ist Ihre Chance als lokaler Experte rund um das Thema Immobilienverkauf zu glänzen. Vorausgesetzt, Ihre Webseite wird bei den relevanten Suchbegriffen sichtbar.
        Wie weit oben werden Sie aktuell bei Google gelistet? Sichern Sie sich als RE/MAX-Makler jetzt eine exklusive, kostenfreie Sichtbarkeitsanalyse Ihrer Webseite und Ihres Google My Business Eintrages.
        Mit klarem Fokus auf die Immobilienwirtschaft, langjähriger Erfahrung und herausragenden Ergebnissen in konkurrenzstarken Gebieten zählt LocalOnTop zu den SEO-Gipfelstürmern für Makler.`],
        registrationLink: 'https://www.localontop.de/remax-lead-turbo',
        logo: 'img/local-on-top.png',
        tags: [PARTNER_TAGS.SEO],
        registrationLinkText: 'Learn more'
      }
    }
  }
}
